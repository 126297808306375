let isKuenne =
  window.location.hostname === "kuenne.openinc.dev" ||
  window.location.hostname === "kuenne-dev.openinc.dev";

//isKuenne = true;
let isKPZ =
  window.location.hostname === "opendash.kompetenzzentrum-siegen.digital";
const config = {
  host: "openware.apps.openinc.dev",
  secure: true,
};
const local = {
  host: "localhost:4567",
  secure: false,
};
const k = {
  host: "kuenne-app.hosts.openinc.de",
  secure: true,
};
const kpz = {
  host: "opendash.kompetenzzentrum-siegen.digital",
  secure: true,
};
const NX = {
  host: "home.opendash.de",
  secure: true,
};

let toUse = config;

if (isKuenne) toUse = k;
if (isKPZ) toUse = kpz;
export default toUse;
