// @ts-check

import "antd/dist/antd.min.css";

import "./parse.config";
import "./leaflet.config";
import "./highcharts.config";

import { init, StorageAdapterLS } from "@opendash/core";
import { registerIconPack } from "@opendash/icons";

import { ParsePlugin, $parse } from "@opendash/plugin-parse";
import Parse from "parse";
import { OpenwarePlugin } from "@opendash/plugin-openware";
import { HighchartsPlugin } from "@opendash/plugin-highcharts";
import { BDEPlugin } from "@opendash/plugin-bde";
//import { GeoPlugin } from "@opendash/plugin-geo";

import openWareConf from "./openware.config.js";
import moment from "moment";

init("opendash", async (factory) => {
  // Logo:

  const isMIAAS = window.location.hostname === "miaas.openinc.dev";
  const isKuenne =
    window.location.hostname === "kuenne.openinc.dev || kuenne-dev.openinc.de";
  const isKPZ =
    window.location.hostname === "opendash.kompetenzzentrum-siegen.digital";
  //factory.ui.setLogoLink("/");
  // factory.ui.setLogoLinkExternal(true);

  if (isMIAAS) {
    factory.ui.setLogoImage(require("../assets/miaas.png"));
  }

  if (isKuenne) {
    factory.ui.setLogoImage(require("../assets/kuenne.png"));
  }
  if (isKPZ) {
    factory.ui.setLogoImage(require("../assets/kpz.png"));
  }
  //
  // factory.ui.disableFooter();

  // Icons
  // @ts-ignore
  registerIconPack(await import("@opendash/icons/dist/fa-regular.json"));

  // Translations:

  factory.registerLanguage("en", "English");
  factory.registerLanguage("de", "Deutsch", "en", true);
  if (window.location.search.indexOf("embedded=true") !== -1) {
    factory.ui.disableHeader();
  }
  factory.registerAntDesignTranslation(
    "en",
    () => import("antd/lib/locale/en_US")
  );

  factory.registerAntDesignTranslation(
    "de",
    () => import("antd/lib/locale/de_DE")
  );

  factory.registerAntDesignTranslation(
    "zh_Hans",
    () => import("antd/lib/locale/zh_cn")
  );

  // parse class translations

  factory.registerTranslationResolver(
    "de",
    "parse-custom",
    async () => await import("./translations/parse/de.json")
  );

  factory.registerTranslationResolver(
    "en",
    "parse-custom",
    async () => await import("./translations/parse/en.json")
  );

  factory.registerTranslationResolver(
    "zh_Hans",
    "parse-custom",
    async () => await import("./translations/parse/zh_Hans.json")
  );

  // widget translations

  factory.registerTranslationResolver(
    "de",
    "widgets",
    async () => await import("./translations/widgets/de.json")
  );

  factory.registerTranslationResolver(
    "en",
    "widgets",
    async () => await import("./translations/widgets/en.json")
  );

  factory.registerTranslationResolver(
    "zh_Hans",
    "widgets",
    async () => await import("./translations/widgets/zh_Hans.json")
  );

  // Adapter + Plugins

  factory.registerDeviceStorageAdapter(new StorageAdapterLS());

  try {
    await factory.use(new BDEPlugin());
  } catch (e) {
    console.error(e, "Could not load BDEPlugin");
  }

  //await factory.use(new GeoPlugin());
  /*
  try {
    const OpenwareDataCollection = await import(
      "@opendash/plugin-datacollection"
    );
  
    await factory.use(
      new OpenwareDataCollection.OpenwareDataCollection({
        secure: openWareConf.secure,
        host: openWareConf.host,
        parseConfig: ParseAdminConfig,
      })
    );
  } catch (e) {
    console.error(e, "Could not load Plugin DataCollection");
  }
*/
  await factory.use(
    new ParsePlugin({
      authLdapActive: true,
    })
  );

  // await factory.use(new ParseAdminPlugin(ParseAdminConfig));

  //await factory.use(new GeoPlugin());

  await factory.use(
    new OpenwarePlugin({
      secure: openWareConf.secure,
      host: openWareConf.host,
    })
  );

  await factory.use(new HighchartsPlugin());

  // factory.use(new GreisPlugin({}));

  // factory.ui.disableHeader();

  // Widgets

  //factory.registerWidget(await import("./widgets/leaflet-history"));
  //factory.registerWidget(await import("./widgets/leaflet-heat"));

  /*factory.registerWidget(
    await import("./widgets/leaflet-district-distribution")
  );*/

  factory.registerWidget(await import("./widgets/table"));
  factory.registerWidget(await import("./widgets/table-aggregate"));
  //factory.registerWidget(await import("./widgets/kpi-map"));

  // Routing:

  // factory.registerRoute({
  //   path: "/route/to/*",
  //   component: () => import("./path/to/component")
  // });

  // Navigation:

  // factory.registerStaticNavigationGroup({
  //   id: "admin/parse",
  //   label: "openware:admin.label",
  //   order: 20,
  // });
  addcss(`
  .ant-modal-close-x {margin-top:16}
  .ant-steps-item-icon span {line-height:32px!important}
  .ant-steps-item-icon svg {margin-top:7}
  .ant-drawer-body .data-item-boolean {margin-top:12} 
  .ant-drawer-body .data-item-percentage {margin-top:10} 
  `);
  if (isMIAAS) {
    factory.registerStaticNavigationItem({
      id: "miaasadmin",
      group: "admin/miaas",
      place: "frontpage",
      order: 10,

      label: "Regulierung",
      icon: "fa:map-signs",
      color: "#71816D",

      link: () => {
        const session = Parse.User.current().getSessionToken() || "";
        window.open(
          "https://miaas-dashboard.dataanalysis.fb01.h-brs.de/login?session=" +
            session,
          "_blank"
        );
      },

      event: "string",

      routeCondition: "**",
      activeCondition: "/",
    });
  }

  // ///////////////////////////////

  $parse.ui.setClassConfig({
    className: "OWPlcDevice",
    disableACLEditing: true,
    titleFields: ["name"],
    displayFields: [
      "name",
      "connectionString",
      "updatedAt",
      "enabled",
      "interval",
      "options",
      "extraOptions",
    ],
    createFields: [
      "name",
      "connectionString",
      "enabled",
      "interval",
      "extraOptions",
      "options",
    ],
    editFields: [
      "name",
      "connectionString",
      "enabled",
      "interval",
      "extraOptions",
      "options",
    ],
  });

  $parse.ui.setDefaultView("OWPlcDevice", {
    type: "table",
  });

  $parse.ui.setClassConfig({
    className: "OWPlcItem",
    disableACLEditing: true,
    titleFields: ["label"],
    displayFields: [
      "label",
      "enabled",
      "OWSource",
      "address",
      "type",
      "updatedAt",
      "onChange",
      "DeviceID",
      "OWSource",
    ],
    createFields: [
      "label",
      "enabled",
      "onChange",
      "address",
      "type",
      "DeviceID",
      "OWSource",
    ],
    editFields: [
      "label",
      "enabled",
      "onChange",
      "address",
      "type",
      "DeviceID",
      "OWSource",
    ],
    asSelect: {
      type: [
        ["Number", "Zahl"],
        ["String", "Zeichenkette"],
        ["Boolean", "Wahrheitswert"],
        ["Geo", "Geoinformation"],
        ["Object", "Datenstruktur"],
      ],
    },
  });

  $parse.ui.setDefaultView("OWPlcItem", {
    type: "table",
  });

  $parse.ui.setClassConfig({
    className: "BCM_Project",
    disableACLEditing: true,
    titleFields: ["label"],
    displayFields: ["label", "description"],
    createFields: ["label", "description"],
    editFields: ["label", "description"],
  });

  $parse.ui.setDefaultView("BCM_Project", {
    type: "table",
    fields: ["label", "description"],
  });

  $parse.ui.setClassConfig({
    className: "BCM_TaskState",
    disableACLEditing: true,
    titleFields: ["label"],
    displayFields: ["label", "description"],
    createFields: ["label", "description"],
    editFields: ["label", "description"],
    orderField: "order",
  });

  $parse.ui.setDefaultView("BCM_TaskState", {
    type: "table",
    fields: ["label", "description"],
  });

  $parse.ui.setClassConfig({
    className: "BCM_Task",
    disableACLEditing: true,
    titleFields: ["label"],
    displayFields: [
      "project",
      "state",
      "label",
      "parent",
      "open",
      "progress",
      "startDate",
      "endDate",
      "assignedTo",
    ],
    createFields: [
      "project",
      "state",
      "label",
      "parent",
      "startDate",
      "endDate",
      "assignedTo",
    ],
    editFields: [
      "project",
      "state",
      "label",
      "parent",
      "open",
      "progress",
      "startDate",
      "endDate",
      "assignedTo",
    ],
    orderField: "order",
  });

  $parse.ui.setDefaultView("BCM_Task", {
    type: "canban",
    groupByField: "state",
    titleFields: ["label"],
    descriptionFields: ["startDate", "endDate"],
  });

  // ///////////////////////////////
}).then((app) => {
  console.log("init open.DASH");
  moment.locale("de");

  // app.services.DataService.fetchDimensionValuesMultiItemLive(
  //   [
  //     [{ source: "demo@video.de", id: "demo.video.energieverbraucha" }, 0],
  //     [{ source: "demo@video.de", id: "demo.video.energieverbrauchb" }, 0],
  //     [{ source: "demo@video.de", id: "demo.video.energieverbrauchc" }, 0],
  //   ],
  //   {
  //     live: true,
  //     historyType: "absolute",
  //     start: Date.now() - 1000 * 60 * 60 * 24 * 7,
  //     end: Date.now(),
  //   },
  //   (value) => {
  //     console.log(value);
  //   }
  // );
  // // app.services.DataService.fetchDimensionValuesMultiItem(
  // //   [[{ source: "demo@video.de", id: "demo.video.statec" }, 0]],
  // //   { live: true, start: Date.now() - 1000 * 60 * 60, end: Date.now() }
  // // ).then((value) => {
  // //   console.log(value);
  // // });
});
function addcss(css) {
  var head = document.getElementsByTagName("head")[0];
  var s = document.createElement("style");
  s.setAttribute("type", "text/css");
  s.appendChild(document.createTextNode(css));
  head.appendChild(s);
}
